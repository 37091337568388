import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/Layout';
import { ChangingUnitImage } from '../components/UnitImage';

const Pagination = ({ currentPage, numPages }) => {
    if (numPages < 2) {
        return null;
    }

    const isFirst = currentPage === 1;
    const isLast = currentPage === numPages;
    const prevPageNum = currentPage - 1;
    const nextPageNum = currentPage + 1;
    const prevPageLink = currentPage - 1 === 1 ? '/' : `/blog/${currentPage - 1}`;
    const nextPageLink = `/blog/${currentPage + 1}`;

    return (
        <nav className="pagination" role="navigation" aria-label="pagination">
            <ul className="pagination-list">
                {isFirst ? null : (
                    <Link className="pagination-previous" to={prevPageLink}>
                        Previous page
                    </Link>
                )}
                {!isFirst && currentPage > 2 ? (
                    <li>
                        <Link className="pagination-link" aria-label="Go to page 1" to={`/`}>
                            1
                        </Link>
                    </li>
                ) : null}
                {!isFirst && currentPage > 2 ? (
                    <li>
                        <span className="pagination-ellipsis">&hellip;</span>
                    </li>
                ) : null}
                {!isFirst ? (
                    <li>
                        <Link className="pagination-link" aria-label={`Go to page ${prevPageNum}`}>
                            {prevPageNum}
                        </Link>
                    </li>
                ) : null}
                <li>
                    <Link
                        className="pagination-link is-current"
                        aria-label={`Page ${currentPage}`}
                        aria-current="page"
                    >
                        {currentPage}
                    </Link>
                </li>
                {!isLast ? (
                    <li>
                        <Link className="pagination-link" aria-label={`Go to page ${nextPageNum}`}>
                            {nextPageNum}
                        </Link>
                    </li>
                ) : null}
                {!isLast && currentPage < numPages ? (
                    <li>
                        <span className="pagination-ellipsis">&hellip;</span>
                    </li>
                ) : null}
                {!isLast ? (
                    <li>
                        <Link className="pagination-link" aria-label={`Go to page ${numPages}`}>
                            {numPages}
                        </Link>
                    </li>
                ) : null}
            </ul>
            {isLast ? null : (
                <Link className="pagination-next" to={nextPageLink}>
                    Next page
                </Link>
            )}
        </nav>
    );
};

export const Head = ({ data }) => <title>{data.site.siteMetadata.title}</title>;

export default function Home({ data, pageContext }) {
    const { currentPage, numPages } = pageContext;

    return (
        <Layout>
            <ChangingUnitImage />
            <div className="container">
                {data.allMarkdownRemark.edges.map(({ node }) => {
                    return (
                        <div className="card mb-4" key={node.id}>
                            <div className="card-content">
                                <h2 className="title is-3">
                                    <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
                                </h2>
                                <p className="my-3">
                                    <small className="tag">
                                        <span className="icon">
                                            <i className="far fa-calendar-alt"></i>
                                        </span>
                                        {node.frontmatter.date}
                                    </small>
                                </p>
                                <div className="content">
                                    <p>{node.excerpt}</p>
                                    <Link className="button is-success" to={node.fields.slug}>
                                        Read more
                                    </Link>
                                </div>
                            </div>
                        </div>
                    );
                })}
                <Pagination currentPage={currentPage} numPages={numPages} />
            </div>
        </Layout>
    );
}

export const query = graphql`
    query blogListQuery($skip: Int!, $limit: Int!) {
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            limit: $limit
            skip: $skip
        ) {
            totalCount
            edges {
                node {
                    id
                    frontmatter {
                        title
                        date(formatString: "DD MMMM YYYY")
                    }
                    fields {
                        slug
                    }
                    excerpt
                }
            }
        }
        site {
            siteMetadata {
                title
            }
        }
    }
`;
